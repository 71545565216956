<template>
  <DetailItemEditCard v-bind="$attrs" v-on="$listeners" :item-name="taskName" :promises.sync="detailItemEditCardPromises"
  :detailViewRouteLocation="{name: routeNames.TASK_DETAIL, params: {projectId: projectId, clientId: clientId,functionalId:functionalId}}"
  :successRouteLocation="successRouteLocation" :formPromises.sync="computedPromises">
  <p>hello</p>
  <template v-slot:form-fields>
      <slot name="form-fields"/>
    </template>
    <template v-slot:buttons-left>
      <slot name="buttons-left"/>
    </template>
    <template v-slot:save-button-content>
      <slot name="save-button-content"/>
    </template>
  </DetailItemEditCard>
</template>

<script>
import DetailItemEditCard from "@/components/shared/DetailItemEditCard.vue"
import routeNames from "@/router/RouteNames";

export default {
  name: "TaskDetailItemEditCard",
  props: {
    projectId: {
      type: String,
      required: true
    },
    clientId: {
      type: String,
      required: true
    },
    functionalId: {
      type: String,
      required: true
    },
    promises: {
      default: () => []
    },
      successRouteLocation: {
      type: Object,
      default: null
    },
  },
  emits: ["update:promises"],

  data() {
    return {
      taskName: null,
      routeNames,
      detailItemEditCardPromises: [this.$store.dispatch('tasksModule/fetchTaskName', {clientId:this.clientId, projectId:this.projectId,functionalId:this.functionalId})
          .then((result) => this.taskName = result)]
    }
  },

  inheritAttrs: false,
  components: {
    DetailItemEditCard,
  },
  computed: {
    computedPromises: {
      get() {
        return this.promises
      },
      set(newValue) {
        this.$emit("update:promises", newValue)
      }
    }
  },
}
</script>

<style scoped>

</style>
